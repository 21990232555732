import { toggleHamburger } from "./toggleHamburger";
import { accordion } from "./accordion";
import { tabMenu } from "./tabMenu";
import { initializeViewport } from "./initializeViewport";
toggleHamburger("js-hamburger", "js-spmenu", 1024);
accordion();
tabMenu();
initializeViewport();

const topMvSliderElement = document.querySelector(".js-top-mv");
if (topMvSliderElement) {
  const topMvSlider = new Splide(topMvSliderElement, {
    breakpoints: {
      768: {},
    },
    type: "fade",
    pagination: false,
    arrows: false,
    autoplay: true,
    rewind: true,
    focus: "center",
    autoWidth: true,
    flickMaxPages: 1,
    flickPower: 50,
  }).mount();
  topMvSlider.on("resize", () => {
    topMvSlider.refresh();
  });
}

const activitySLiderElement = document.querySelector(".js-activity-slider");
if (activitySLiderElement) {
  const activitySlider = new Splide(activitySLiderElement, {
    breakpoints: {
      768: {
        gap: "40px",
      },
    },
    type: "loop",
    pagination: false,
    arrows: false,
    // perPage: 4,
    rewind: true,
    autoWidth: true,
    gap: "85px",
    perMove: 1,
  }).mount();
  activitySlider.on("resize", () => {
    activitySlider.refresh();
  });
}

document.addEventListener("DOMContentLoaded", function () {
  const scrollToTopButton = document.querySelector(".js-button-pagetop");
  if (scrollToTopButton) {
    scrollToTopButton.addEventListener("click", function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }
});
